@import './styles/mixins/m-lineclamp.scss';
@import './styles/mixins/m-placeholder.scss';
@import './styles/mixins/m-scrollbar.scss';

$px: 1em/16;
$rpx: 1rem/16;

$facebook: #3b5999;
$flickr: #ff0084;
$instagram: #e4405f;
$linkedin: #0077b5;
$swivle: #f27e6d;
$twitter: #55acee;
$vimeo: #1ab7ea;
$youtube: #cd201f;

$green: #4ec0c3;
$blue: #3f97bd;
$light-grey: #f9f9f9;
$brown-grey: #909090;
$dark-grey: #3c3c3b;
$text: #3c3c3b;
$background: #f0f0f0;
$line: rgba(#979797, 0.2);
$gradient: linear-gradient(90deg, $blue 0%, $green 100%);
$gradient-diagonal: linear-gradient(135deg, $blue 0%, $green 100%);

//MEDIA QUERIES
$breakpoints: (
	default: 320,
	small: 460,
	tablet: 600,
	ipad-port: 768,
	ipad-land: 1024,
	desktop: 1160,
	desktop-large: 1200,
	large: 1600,
	extreme: 2240
);

@mixin breakpoint($breakpoint: 'default') {
	@if map-has-key($breakpoints, $breakpoint) {
		@media only screen and (min-width: map-get($breakpoints, $breakpoint) + 'px') {
			@content;
		}
	}
}

@function getBreakpointWidth($breakpoint: 'default') {
	@return map-get($breakpoints, $breakpoint);
}

@function getColWidth($breakpoint: 'default') {
	$breakpointWidth: getBreakpointWidth($breakpoint);
	$originalColWidth: ($breakpointWidth - (11 * getGutterWidth($breakpoint))) /
		12;
	@return $originalColWidth;
}

@function getGutterWidth($breakpoint: 'default') {
	@return map-get(
		(
			'default': 5,
			'small': 15,
			'tablet': 30,
			'ipad-port': 30,
			'ipad-land': 30,
			'desktop': 30,
			'desktop-large': 30,
			'large': 30,
			'extreme': 30
		),
		$breakpoint
	);
}

//GRID
@function calcGrid($cols: 1, $gutters: 0, $breakpoint: 'default') {
	$gutWidth: getGutterWidth($breakpoint);
	$colWidth: getColWidth($breakpoint);
	$breakpointWidth: getBreakpointWidth($breakpoint);
	$totalWidth: (($cols * $colWidth) + ($gutters * $gutWidth));
	@return calc(100% / #{$breakpointWidth} * #{$totalWidth});
}

@mixin gridMinWidth($cols: 1, $gutters: 0) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			min-width: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}

@mixin gridMaxWidth($cols: 1, $gutters: 0) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			max-width: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}

@mixin gridMarginLeft($cols: 1, $gutters: 1) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			margin-left: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}

@mixin gridMarginRight($cols: 1, $gutters: 1) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			margin-right: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}

@mixin gridPaddingLeft($cols: 1, $gutters: 1) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			padding-left: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}

@mixin gridPaddingRight($cols: 1, $gutters: 1) {
	@each $breakpointKey, $breakpointData in $breakpoints {
		@include breakpoint($breakpointKey) {
			padding-right: calcGrid($cols, $gutters, $breakpointKey);
		}
	}
}
