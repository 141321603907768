@import 'style';

.filtertag {
	color: white;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12 * $px;
	font-weight: 500;
	margin: 5 * $px 0 0 10 * $px;
	padding: 5 * $px 15 * $px 5 * $px 12 * $px;
	background-color: $dark-grey;
	border-radius: 50 * $px;
	border: 1px solid $dark-grey;
	line-height: 1.5;
	vertical-align: top;
	transition: all 0.25s ease-out;
	cursor: pointer;

	&.mod-white {
		color: $text;
		background-color: white;

		&-icon {
			color: $text;
		}
	}

	&:not(.is-inactive):not(.is-locked):hover {
		.filtertag-content {
			transform: translateX(0);
		}
		.filtertag-icon {
			opacity: 1;
		}
	}

	&.is-inactive:not(:hover) {
		color: $text;
		background-color: transparent;

		&.is-passive {
			background: #f0f0f0;
		}

		.filtertag-icon {
			background-color: transparent;
			color: $text;
		}
	}

	&-content {
		display: inline-block;
		transition: transform 0.15s ease-out;
		transform: translateX(10 * $px);
	}

	&-icon {
		font-size: 10 * $px;
		margin-left: 10 * $px;
		margin-right: -3 * $px;
		display: inline-block;
		vertical-align: middle;
		color: currentColor;
		opacity: 0;
	}
}
