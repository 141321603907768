@import 'style';

.contentsidebar {
	position: relative;
}

.content {
	width: 100%;

	@include breakpoint('ipad-port') {
		display: inline-block;
		vertical-align: top;
		@include gridMaxWidth(9, 8);
	}

	@include breakpoint('ipad-land') {
		@include gridMaxWidth(8, 7);
	}
}

.sidebar {
	width: 100%;

	@include breakpoint('ipad-port') {
		display: inline-block;
		vertical-align: top;
		@include gridMaxWidth(3, 2);
	}
}

.content + .sidebar {
	margin-top: 50 * $px;

	@include breakpoint('ipad-port') {
		margin-top: 0;
		@include gridMarginLeft(0, 1);
	}

	@include breakpoint('ipad-land') {
		@include gridMarginLeft(1, 2);
	}
}
